<template>
  <div>
    <div class="row">
      <div class="col-12">
        <fieldset class="border border-3 p-2">
          <legend class="float-none text-start w-auto px-3 text-start">
            Termostat pondělí - pátek &nbsp;&nbsp;
            <button v-on:click="addWeekDay" class="btn btn-sm btn-success" onclick="this.blur()">
              <font-awesome-icon icon="plus"></font-awesome-icon>
            </button>
            &nbsp;
            <router-link to="/">
              <button class="btn btn-sm btn-outline-light">
                Zpět na přehled
              </button>
            </router-link>
          </legend>
          <div v-for="setting in settings" :key="setting.id">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-2 text-center" style="line-height: 3rem">
                    nastav od
                  </div>
                  <div class="col-2" style="line-height: 3rem; font-size: 3rem">
                    {{ setting.from }}
                  </div>
                  <div class="offset-1 col-2" style="line-height: 3rem">
                    teplota
                  </div>
                  <div class="col-2" style="font-size: 3rem; line-height: 3rem">
                    {{ setting.temperature }}°
                  </div>
                  <div class="col-2" style="line-height: 3rem">
                    aktivni
                  </div>
                  <div class="col-1">
                    <button v-on:click="deleteSetting(setting.id)" class="btn btn-sm btn-outline-light">
                      <font-awesome-icon icon="trash" style="color: red"></font-awesome-icon>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2 text-center">
                    <button v-on:click="minusTime(setting.id)" class="btn btn-outline-light btn-lg">
                      <font-awesome-icon icon="minus" style="color: #0dcaf0"></font-awesome-icon>
                    </button>
                  </div>
                  <div class="col-2 text-center">
                    <button v-on:click="plusTime(setting.id)" class="btn btn-outline-light btn-lg">
                      <font-awesome-icon icon="plus" style="color: red"></font-awesome-icon>
                    </button>
                  </div>
                  <div class="offset-1 col-2 text-center">
                    <button v-on:click="minusTemp(setting.id)" class="btn btn-outline-light btn-lg">
                      <font-awesome-icon icon="minus" style="color: #0dcaf0"></font-awesome-icon>
                    </button>
                  </div>
                  <div class="col-2 text-center">
                    <button v-on:click="plusTemp(setting.id)" class="btn btn-outline-light btn-lg">
                      <font-awesome-icon icon="plus" style="color: red"></font-awesome-icon>
                    </button>
                  </div>
                  <div class="col-2 text-center">
                    <button v-on:click="activeInactive(setting.id)" class="btn btn-outline-light btn-lg">
                      <font-awesome-icon v-if="setting.active" icon="check" style="color: green"></font-awesome-icon>
                      <font-awesome-icon v-if="!setting.active" icon="times" style="color: red"></font-awesome-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hr/>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <fieldset class="border border-3 p-2">
          <legend class="float-none text-start w-auto px-3 text-start">
            Termostat sobota - neděle &nbsp;&nbsp;
            <button v-on:click="addWeekend" class="btn btn-sm btn-success" onclick="this.blur()">
              <font-awesome-icon icon="plus"></font-awesome-icon>
            </button>
          </legend>
          <div v-for="setting in settingsWeekend" :key="setting.id">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-2 text-center" style="line-height: 3rem">
                    nastav od
                  </div>
                  <div class="col-2" style="line-height: 3rem; font-size: 3rem">
                    {{ setting.from }}
                  </div>
                  <div class="offset-1 col-2" style="line-height: 3rem">
                    teplota
                  </div>
                  <div class="col-2" style="font-size: 3rem; line-height: 3rem">
                    {{ setting.temperature }}°
                  </div>
                  <div class="col-2" style="line-height: 3rem">
                    aktivni
                  </div>
                  <div class="col-1">
                    <button v-on:click="deleteSettingWeekend(setting.id)" class="btn btn-sm btn-outline-light">
                      <font-awesome-icon icon="trash" style="color: red"></font-awesome-icon>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2 text-center">
                    <button v-on:click="minusTimeWeekend(setting.id)" class="btn btn-outline-light btn-lg">
                      <font-awesome-icon icon="minus" style="color: #0dcaf0"></font-awesome-icon>
                    </button>
                  </div>
                  <div class="col-2 text-center">
                    <button v-on:click="plusTimeWeekend(setting.id)" class="btn btn-outline-light btn-lg">
                      <font-awesome-icon icon="plus" style="color: red"></font-awesome-icon>
                    </button>
                  </div>
                  <div class="offset-1 col-2 text-center">
                    <button v-on:click="minusTempWeekend(setting.id)" class="btn btn-outline-light btn-lg">
                      <font-awesome-icon icon="minus" style="color: #0dcaf0"></font-awesome-icon>
                    </button>
                  </div>
                  <div class="col-2 text-center">
                    <button v-on:click="plusTempWeekend(setting.id)" class="btn btn-outline-light btn-lg">
                      <font-awesome-icon icon="plus" style="color: red"></font-awesome-icon>
                    </button>
                  </div>
                  <div class="col-2 text-center">
                    <button v-on:click="activeInactiveWeekend(setting.id)" class="btn btn-outline-light btn-lg">
                      <font-awesome-icon v-if="setting.active" icon="check" style="color: green"></font-awesome-icon>
                      <font-awesome-icon v-if="!setting.active" icon="times" style="color: red"></font-awesome-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hr/>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThermostatSettings",
  data() {
    return {
      settings: [],
      settingsWeekend: []
    }
  },
  mounted() {
    this.fetchData()
    this.fetchDataWeekend()
  },
  methods: {
    fetchData() {
      this.$http.get(this.$apiUrl + "/week-day")
          .then(response => this.mapToTemplate(response))
    },
    addWeekDay() {
      this.$http.post(this.$apiUrl + "/week-day").then(response => {
        if (response.status === 201) {
          this.fetchData()
        }
      })
    },
    plusTime(id) {
      this.$http.patch(this.$apiUrl + "/week-day/" + id + "/time", {
        direction: "up"
      }).then(response => this.mapToTemplate(response))
    },
    minusTime(id) {
      this.$http.patch(this.$apiUrl + "/week-day/" + id + "/time", {
        direction: "down"
      }).then(response => this.mapToTemplate(response))
    },
    plusTemp(id) {
      this.$http.patch(this.$apiUrl + "/week-day/" + id + "/temperature", {
        direction: "up"
      }).then(response => this.mapToTemplate(response))
    },
    minusTemp(id) {
      this.$http.patch(this.$apiUrl + "/week-day/" + id + "/temperature", {
        direction: "down"
      }).then(response => this.mapToTemplate(response))
    },
    deleteSetting(id) {
      this.$http.delete(this.$apiUrl + "/week-day/" + id).then(response => {
        if (response.status === 204) {
          this.fetchData()
        }
      })
    },
    activeInactive(id) {
      this.$http.patch(this.$apiUrl + "/week-day/" + id + "/active").then(response => {
        if (response.status === 200) {
          this.mapToTemplate(response)
        }
      })
    },
    mapToTemplate(response) {
      this.settings = response.data
    },
    fetchDataWeekend() {
      this.$http.get(this.$apiUrl + "/week-end")
          .then(response => this.mapToTemplateWeekend(response))
    },
    addWeekend() {
      this.$http.post(this.$apiUrl + "/week-end").then(response => {
        if (response.status === 201) {
          this.fetchDataWeekend()
        }
      })
    },
    plusTimeWeekend(id) {
      this.$http.patch(this.$apiUrl + "/week-end/" + id + "/time", {
        direction: "up"
      }).then(response => this.mapToTemplateWeekend(response))
    },
    minusTimeWeekend(id) {
      this.$http.patch(this.$apiUrl + "/week-end/" + id + "/time", {
        direction: "down"
      }).then(response => this.mapToTemplateWeekend(response))
    },
    plusTempWeekend(id) {
      this.$http.patch(this.$apiUrl + "/week-end/" + id + "/temperature", {
        direction: "up"
      }).then(response => this.mapToTemplateWeekend(response))
    },
    minusTempWeekend(id) {
      this.$http.patch(this.$apiUrl + "/week-end/" + id + "/temperature", {
        direction: "down"
      }).then(response => this.mapToTemplateWeekend(response))
    },
    deleteSettingWeekend(id) {
      this.$http.delete(this.$apiUrl + "/week-end/" + id).then(response => {
        if (response.status === 204) {
          this.fetchDataWeekend()
        }
      })
    },
    activeInactiveWeekend(id) {
      this.$http.patch(this.$apiUrl + "/week-end/" + id + "/active").then(response => {
        if (response.status === 200) {
          this.mapToTemplateWeekend(response)
        }
      })
    },
    mapToTemplateWeekend(response) {
      this.settingsWeekend = response.data
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <ThermostatSettings/>
</template>

<script>
import ThermostatSettings from "@/components/ThermostatSettings";
export default {
  name: "Settings",
  components: {ThermostatSettings}
}
</script>

<style scoped>

</style>